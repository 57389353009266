import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { TopbarGrid, IconsWrapper, IconContainer, NameContainer, Languagecontainer, SvgIconContainer, Logo, ArrowImg, CollapsArrow } from './style';
import { TopbarGrid, IconsWrapper, NameContainer, Logo, /*ArrowImg,*/ CollapsArrow, IconContainer, Languagecontainer, ProfileImg, ViewButton } from './style';
//import {ProfileContainer,Settings} from '../TopBarV2/style'
import { ImageUrl } from '../../utils/constants';
import Notification from '../Notification';
import { COMPANY_NAME } from '../../utils/config';
import UserProfile from '../UserProfile';
import { putUpdateLanguage } from '../../redux/actions';
import { connect } from 'react-redux';
import i18n from '../../Lang/i18n';
import  {Img}  from "react-image";
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { withTranslation } from 'react-i18next';

// const dashboardIcon = (
//   <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path
//       d="M14.52 3.86054C6.93 1.44054 0 7.05054 0 14.3105C0 15.3005 0.11 17.1705 0.44 18.2705C0.44 18.4905 0.66 18.7105 0.88 18.7105H21.01C21.23 18.7105 21.45 18.4905 21.56 18.2705C21.78 17.1705 22 15.3005 22 14.3105C22 9.58054 19.03 5.29054 14.52 3.86054ZM11 5.51054C11.66 5.51054 12.1 5.95054 12.1 6.61054C12.1 7.27054 11.66 7.71054 11 7.71054C10.34 7.71054 9.9 7.27054 9.9 6.61054C9.9 5.95054 10.34 5.51054 11 5.51054ZM3.3 15.4105C2.64 15.4105 2.2 14.9705 2.2 14.3105C2.2 13.6505 2.64 13.2105 3.3 13.2105C3.96 13.2105 4.4 13.6505 4.4 14.3105C4.4 14.9705 3.96 15.4105 3.3 15.4105ZM6.38 9.69054C5.94 10.1305 5.28 10.1305 4.84 9.69054C4.4 9.25054 4.4 8.48054 4.73 8.04054C5.17 7.60054 5.83 7.60054 6.27 8.04054C6.71 8.48054 6.71 9.25054 6.38 9.69054ZM11 16.5105C9.79 16.5105 8.69 15.4105 9.02 14.3105C9.35 13.2105 11 9.91054 11 9.91054C11 9.91054 12.65 13.2105 12.98 14.3105C13.31 15.4105 12.21 16.5105 11 16.5105ZM17.27 9.69054C16.83 10.1305 16.17 10.1305 15.73 9.69054C15.29 9.25054 15.29 8.59054 15.73 8.15054C16.17 7.71054 16.83 7.71054 17.27 8.15054C17.6 8.48054 17.6 9.25054 17.27 9.69054ZM18.7 15.4105C18.04 15.4105 17.6 14.9705 17.6 14.3105C17.6 13.6505 18.04 13.2105 18.7 13.2105C19.36 13.2105 19.8 13.6505 19.8 14.3105C19.8 14.9705 19.36 15.4105 18.7 15.4105Z" />
//   </svg>);

const checkBase64 = (image) => {
  let arr = image.split('/');
  if (arr[0] == 'profile-Image') {
    return `${ImageUrl}/${image}`
  }
  else {
    return image;
  }
};

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideSideBar: false,
      // lang: localStorage.getItem('lang') || 'en' || 'es',
      lang: localStorage.getItem('lang'),
      showProfileImage: false,
    }
    this.myref = React.createRef();
  }

  collapsSideBar = () => {
    const { hideContentOfSideBar, handleResponsiveCheck, handleAdminResponsiveCheck } = this.props;
    this.setState({ hideSideBar: !this.state.hideSideBar });
    hideContentOfSideBar();
    handleResponsiveCheck();
    handleAdminResponsiveCheck();
  }

  takeLanguageData = (lang) => {
    const { putUpdateLanguage } = this.props;
    let obj = {
      user_language: lang === 'en' ? "english" : lang === 'es' ? 'Spanish' : lang === 'pt' ? 'Portuguese': 'French'
    };
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
    this.setState({ lang: lang });
    putUpdateLanguage(obj);
  };

  componentDidMount() {
    const { getLanguages } = this.props;
    const { lang } = this.state;
    let selectedUserLanguage;
    if(getLanguages && !isNull(getLanguages) &&!isUndefined(getLanguages)){
      getLanguages && getLanguages.length>0 && getLanguages.map((lang)=>{
        if(lang.user_selected===1){
          selectedUserLanguage=lang.name;
        }
      })
    }
    if(isNull(lang)){
      i18n.changeLanguage(selectedUserLanguage);
    }else{
      i18n.changeLanguage(lang);
    }
  }

  toggleProfileSidebar = () => {
    this.setState((prev) => ({
      showProfileImage: !prev.showProfileImage
    }));
  };


  render() {
    const { history, logout, companyLogo, userDetails, profileImage, readStatusCount, adminPortalAccess, colorData, getLanguages, companyId } = this.props;
    let navIndex = colorData && colorData.color_branding && colorData.color_branding.length > 0 ? colorData.color_branding.findIndex((data) => data.custom_name === 'nav_bar_color') : -1;
    let getColor = navIndex !== -1 ? colorData?.color_branding[navIndex]?.color_code : "#fff";
    let sideBarIndex = colorData && colorData.color_branding && colorData.color_branding.length > 0 ? colorData.color_branding.findIndex((data) => data.custom_name === 'side_menu_color') : -1;
    let getSideBarColor = sideBarIndex !== -1 && colorData?.color_branding[sideBarIndex]?.color_code;
    let { lang, showProfileImage } = this.state;
    let selectedLanguage;
    if(getLanguages && !isNull(getLanguages) &&!isUndefined(getLanguages)){
      getLanguages && getLanguages.length>0 && getLanguages.map((lang)=>{
        if(lang.user_selected===1){
          selectedLanguage=lang.name;
        }
      })
    }
    return <TopbarGrid backgroundColor={getColor}>
      <Logo color={getSideBarColor || 'rgb(11, 65, 112)'} height={1} hide={this.state.hideSideBar ? 1 : 0} width={companyId===83}>
        {!this.state.hideSideBar ? companyLogo
          ?
          <div className={'imageContainer'}>
            <img src={`${ImageUrl}/${companyLogo}`} alt="logo" onClick={() => history.push('/portal')} />
          </div>
          :
          COMPANY_NAME === 'Woliba' ?
            <div className={'imageContainer'}>
              <img src="/public/images/NewSideBarV3//logo.png" alt="logo" onClick={() => history.push('/portal')} />
            </div>
            :
            <div className={'imageContainer'}>
              <img src="/public/images/Logo/training-amigo-horizontal-logo.png" alt="logo"
                onClick={() => history.push('/portal')} />
            </div>
          :
          <CollapsArrow onClick={this.collapsSideBar}>
            <svg width="20" height="22" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.6083 7.05099L8.95285 0.395524C8.42792 -0.1294 7.57658 -0.1294 7.05165 0.395524C6.52673 0.920448 6.52673 1.77179 7.05165 2.29672L12.7559 8.00092L7.05165 13.7051C6.52673 14.23 6.52673 15.0814 7.05165 15.6063C7.57658 16.1312 8.42792 16.1312 8.95285 15.6063L15.6083 8.95085C16.1332 8.42723 16.1332 7.57591 15.6083 7.05099Z"
                fill={getSideBarColor || '#0b4170'} opacity="0.5" />
              <path
                d="M7.6039 6.64798L2.32005 1.07901C1.78911 0.52013 0.92914 0.52013 0.398203 1.07901C-0.132734 1.6379 -0.132734 2.54453 0.398203 3.1041L4.72149 7.66052L0.398203 12.2169C-0.132734 12.7759 -0.132734 13.6831 0.398203 14.242C0.92914 14.8009 1.78911 14.8009 2.32005 14.242L7.6039 8.67306C8.13484 8.11418 8.13484 7.20754 7.6039 6.64798Z"
                fill={getSideBarColor || '#0b4170'} opacity="0.1" />
            </svg>
          </CollapsArrow>
        }
        {/* {this.state.hideSideBar ? null :
          <ArrowImg onClick={this.collapsSideBar}>
            <svg width="20" height="22" viewBox="0 0 17 16" fill="none">
              <path
                d="M0.393665 7.05099L7.04911 0.395524C7.57403 -0.1294 8.42538 -0.1294 8.9503 0.395524C9.47523 0.920448 9.47523 1.77179 8.9503 2.29672L3.24607 8.00092L8.9503 13.7051C9.47523 14.23 9.47523 15.0814 8.9503 15.6063C8.42538 16.1312 7.57403 16.1312 7.04911 15.6063L0.393665 8.95085C-0.131259 8.42723 -0.131259 7.57591 0.393665 7.05099Z"
                fill={getSideBarColor || '#0b4170'} fillOpacity="0.5" />
              <path
                d="M8.39805 6.64798L13.6819 1.07901C14.2128 0.52013 15.0728 0.52013 15.6038 1.07901C16.1347 1.6379 16.1347 2.54453 15.6038 3.1041L11.2805 7.66052L15.6038 12.2169C16.1347 12.7759 16.1347 13.6831 15.6038 14.242C15.0728 14.8009 14.2128 14.8009 13.6819 14.242L8.39805 8.67306C7.86711 8.11418 7.86711 7.20754 8.39805 6.64798Z"
                fill={getSideBarColor || '#0b4170'} fillOpacity="0.1" />
            </svg>
          </ArrowImg>
        } */}
      </Logo>
      <IconsWrapper>
        {
          adminPortalAccess && <div>
            <ViewButton background={getSideBarColor} color={getSideBarColor} onClick={()=> history.location.pathname.includes('company') ? history.push('/portal') : history.push('/company/dashboard') }>{history.location.pathname.includes('company') ? this.props.t("View As Member") : this.props.t("View As Admin")}</ViewButton>
          </div> 
        }
        {/* {
          adminPortalAccess && <div><SvgIconContainer onClick={() => history.push('/company/dashboard')}
            active={history.location.pathname.includes('company')}
            color={getSideBarColor}>{dashboardIcon}</SvgIconContainer>
          </div>
        } */}
        {<Notification readStatusCount={readStatusCount} history={history} color={getSideBarColor} companyId={companyId} companyLogo={companyLogo}/>}
        { getLanguages && getLanguages.length>1?
          <div>
            <Languagecontainer color={getSideBarColor}>
              <IconContainer 
                active={history.location.pathname.includes('community')} color={getSideBarColor}
              >
                {isNull(lang)?<img src={`/public/images/NewHeader/${selectedLanguage == 'en' ? "English.png" : selectedLanguage == 'es' ? "Spanish.png" :selectedLanguage == 'pt'? "Portuguese.png": "French.png"}`} />:<img src={`/public/images/NewHeader/${lang == 'en' ? "English.png" : lang == 'es' ? "Spanish.png" : lang == 'pt'? 'Portuguese.png': "French.png"}`} />}
              </IconContainer>
              <ul>
                {getLanguages.map((data, index)=>
                  (<li key={index}><a onClick={() => this.takeLanguageData(data.name)}><img src={`${ImageUrl}/${data.image}`} />{data.display_name}</a></li>))}
              </ul>
            </Languagecontainer>
          </div>
          : null
        }
        <div>
          <NameContainer /*onClick={() => history.push('/buddy-search')}*/
            /*active={history.location.pathname.includes('buddy-search')}*/ color={getSideBarColor}
          >
            <p>Hi, <span>{userDetails && userDetails.fname}</span></p>
          </NameContainer>
        </div>
        {userDetails && userDetails.profile_image&&<div>
          <ProfileImg color={getSideBarColor} onClick={this.toggleProfileSidebar}>
            <Img
              alt={userDetails && userDetails.fname}
              src={checkBase64(profileImage || userDetails.profile_image)}
              unloader={
                <Img
                  src='/public/images/neutral_avatar.svg'
                  alt='avatar'
                />
              }
            />
          </ProfileImg>
        </div>}
        {showProfileImage ?
          <UserProfile innerRef={this.myref} history={history} logout={logout} profileImage={profileImage} color={getSideBarColor} userDetails={userDetails} showProfileImage={showProfileImage} closeModal={this.toggleProfileSidebar}/>
          : ''}
      </IconsWrapper>
    </TopbarGrid>
  }
}

Topbar.propTypes = {
  history: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  companyLogo: PropTypes.string,
  userDetails: PropTypes.object.isRequired,
  profileImage: PropTypes.string,
  readStatusCount: PropTypes.object,
  getReadStatusCountForNotification: PropTypes.func,
  adminPortalAccess: PropTypes.bool,
  colorData: PropTypes.object,
  hideContentOfSideBar: PropTypes.func,
  handleResponsiveCheck: PropTypes.func.isRequired,
  handleAdminResponsiveCheck: PropTypes.func.isRequired,
  putUpdateLanguage: PropTypes.func,
  userCompany: PropTypes.object,
  showProfileImage: PropTypes.bool,
  getLanguages: PropTypes.array,
  companyId: PropTypes.number,
  t: PropTypes.func
}

const mapStateToProps = (state) => ({
  userCompany: state.profileData.userCompany,
})

const mapDispatchToProps = (dispatch) => ({
  putUpdateLanguage: (obj) => dispatch(putUpdateLanguage(obj))
});
export default connect(mapStateToProps, mapDispatchToProps)((withTranslation())(Topbar));
